import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import pluralize from 'utils/pluralize';
import { CATEGORY, GROUP, PRICING } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { FormSection } from 'components/FormSection';
import { AutocompleteTextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { ModalDelete } from 'components/ModalDelete';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm, ModalToDefault } from './components';

const PricingContainer = () => {
  const { adminRights, user } = useUser();
  const { themeColors } = useTheme();

  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState('');
  const [defaultRows, setDefaultRows] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupPricings, setSelectedGroupPricings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allPricings, setAllPricings] = useState([]);
  const [isDefaultPricing, setIsDefaultPricing] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState({});
  const isSuperAdmin = user.roles.includes('SUPERADMIN');

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatPricingCriteria = list => {
    const nbCategories = list.length;

    if (nbCategories === 0) {
      return <Text>Aucune</Text>;
    }
    const str = `${nbCategories} ${pluralize('catégorie', nbCategories)}`;

    const tooltipContent = () => {
      return (
        <Container>
          <Row spacing={0}>
            <Item>{list.map(el => getToolTipText(el))}</Item>
          </Row>
        </Container>
      );
    };
    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text color="global">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const handleCreatePricing = data => {
    PRICING.createPricing(data).then(() => {
      setModal(null);
      setLoaded(false);
    });
  };

  const selectPricing = id => {
    const pricing = [...defaultRows, ...selectedGroupPricings].find(
      el => el._id === id
    );
    setSelectedPricing(pricing);
  };

  const closeModal = () => {
    setModal(null);
    setSelectedPricing({});
  };

  const addPricing = () => {
    if (adminRights?.general?.tarification?.create && isSuperAdmin) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const handleUpdate = (id, isDefault) => {
    setIsDefaultPricing(isDefault);
    const temporarySelectedPricing = allPricings.find(
      pricing => pricing._id === id
    );
    setSelectedPricing(temporarySelectedPricing);
    setModal('modalUpdate');
  };

  const getPricings = async (groupId = null, type = null) => {
    try {
      const result = await PRICING.getPricings(groupId, type);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getAllGroups = async () => {
    try {
      const result = await GROUP.getGroups();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const filterRows = newRows => {
    setDefaultRows(newRows);
  };

  const handleSelectedGroup = group => {
    setSelectedGroup(group);
  };

  const getDatas = async () => {
    const [pricings, allGroups, allCategories] = await Promise.all([
      getPricings(null, 'default'),
      getAllGroups(),
      CATEGORY.getFullCategoryList()
    ]);
    setGroups(allGroups);
    setCategories(
      allCategories.reduce((acc, el) => {
        if (el.parentKey) {
          acc.push({
            key: el.key,
            id: el._id,
            label: el.name
          });
        }
        return acc;
      }, [])
    );
    if (pricings.length) {
      const defaultPricings = pricings.filter(
        pricing => pricing.type === 'default'
      );
      const mappedDefaultPricings = defaultPricings.reduce((acc, el) => {
        const defaultPricing = {
          ...el,
          cpmCampaignBoth: `${el.cpmCampaign}/${el.cpmCrm}`,
          criteriaCategories: formatPricingCriteria(el.criteriaCategories),
          criteriaCategoriesArray: el.criteriaCategories
        };
        acc.push(defaultPricing);
        return acc;
      }, []);

      const formatedPricings = pricings.reduce((acc, el) => {
        const defaultPricing = defaultRows.find(dp => dp.key === el.key);
        const formatedPricing = {
          ...el,
          name: el.name || defaultPricing.name,
          cpmCampaign: el.cpmCampaign || defaultPricing.cpmCampaign,
          cpmCrm: el.cpmCrm || defaultPricing.cpmCrm,
          cpmEnrichment: el.cpmEnrichment || defaultPricing.cpmEnrichment,
          cpmExport: el.cpmExport || defaultPricing.cpmExport,
          criteriaCategories:
            el?.criteriaCategories?.length !== 0
              ? el.criteriaCategories
              : defaultPricing?.criteriaCategoriesArray || []
        };
        acc.push(formatedPricing);
        return acc;
      }, []);
      setDefaultRows(mappedDefaultPricings);
      setAllRows(mappedDefaultPricings);
      setAllPricings(formatedPricings);
      setRows(pricings);
    } else {
      setAllRows([]);
      setRows([]);
    }
    setLoaded(true);
  };
  const getGroupPricings = async groupId => {
    try {
      const groupPricings = await getPricings(groupId);
      if (groupPricings.length && defaultRows.length) {
        const mappedResults = groupPricings.reduce((acc, groupPricing) => {
          const defaultPricing = defaultRows.find(
            dp => dp.key === groupPricing.key
          );

          if (defaultPricing) {
            const updatedPricing = {
              ...groupPricing,
              name: {
                value: groupPricing.name || defaultPricing.name,
                fromGroup: !!groupPricing.name
              },
              cpmCampaignBoth: {
                cpmCampaign: {
                  value: groupPricing.cpmCampaign || defaultPricing.cpmCampaign,
                  fromGroup: !!groupPricing.cpmCampaign
                },
                cpmCrm: {
                  value: groupPricing.cpmCrm || defaultPricing.cpmCrm,
                  fromGroup: !!groupPricing.cpmCrm
                }
              },
              cpmEnrichment: {
                value:
                  groupPricing.cpmEnrichment || defaultPricing.cpmEnrichment,
                fromGroup: !!groupPricing.cpmEnrichment
              },
              cpmExport: {
                value: groupPricing.cpmExport || defaultPricing.cpmExport,
                fromGroup: !!groupPricing.cpmExport
              },
              criteriaCategories: {
                value:
                  groupPricing.criteriaCategories.length !== 0
                    ? formatPricingCriteria(groupPricing.criteriaCategories)
                    : defaultPricing.criteriaCategories,
                fromGroup: groupPricing.criteriaCategories.length !== 0
              }
            };
            acc.push(updatedPricing);
          } else {
            acc.push({
              ...groupPricing,
              name: { value: groupPricing.name, fromGroup: true },
              cpmCampaignBoth: {
                cpmCampaign: {
                  value: groupPricing.cpmCampaign,
                  fromGroup: true
                },
                cpmCrm: { value: groupPricing.cpmCrm, fromGroup: true }
              },
              cpmEnrichment: {
                value: groupPricing.cpmEnrichment,
                fromGroup: true
              },
              cpmExport: { value: groupPricing.cpmExport, fromGroup: true },
              criteriaCategories: {
                value: formatPricingCriteria(groupPricing.criteriaCategories),
                fromGroup: true
              }
            });
          }

          return acc;
        }, []);

        const formatedPricings = groupPricings.reduce((acc, el) => {
          const defaultPricing = defaultRows.find(dp => dp.key === el.key);
          const formatedPricing = {
            ...el,
            name: el.name || defaultPricing.name,
            cpmCampaign: el.cpmCampaign || defaultPricing.cpmCampaign,
            cpmCrm: el.cpmCrm || defaultPricing.cpmCrm,
            cpmEnrichment: el.cpmEnrichment || defaultPricing.cpmEnrichment,
            cpmExport: el.cpmExport || defaultPricing.cpmExport,
            criteriaCategories: el?.criteriaCategories?.length
              ? el.criteriaCategories
              : defaultPricing?.criteriaCategoriesArray || []
          };
          acc.push(formatedPricing);
          return acc;
        }, []);
        setAllPricings(prev => [...prev, ...formatedPricings]);
        setSelectedGroupPricings(mappedResults);
        return mappedResults;
      }

      setSelectedGroupPricings(groupPricings);
      return groupPricings;
    } catch (err) {
      console.error(err);
      setSelectedGroupPricings([]);
      return [];
    }
  };

  const updateGroupPricing = async () => {
    const datas = selectedGroupPricings.reduce((acc, el) => {
      const body = {
        _id: el._id,
        name: null,
        cpmCampaign: null,
        cpmCrm: null,
        cpmEnrichment: null,
        cpmExport: null,
        criteriaCategories: []
      };
      acc.push(body);
      return acc;
    }, []);
    await PRICING.updateManyPricings(datas);
    setLoaded(false);
    getGroupPricings(selectedGroup?._id);
    closeModal();
  };

  useEffect(() => {
    if (selectedGroup?._id) {
      getGroupPricings(selectedGroup._id);
    }
    // eslint-disable-next-line
  }, [selectedGroup]);

  const handleDelete = id => {
    const temporarySelectedPricing = rows.find(pricing => pricing._id === id);
    setSelectedPricing(temporarySelectedPricing);
    setModal('modalDelete');
  };

  const handleUpdatePricing = async data => {
    let payload = {
      _id: data._id
    };
    if (!isDefaultPricing) {
      const defaultPricing = defaultRows.find(
        pricing => pricing.key === data.key
      );

      if (data.name !== defaultPricing.name) {
        payload.name = data.name;
      }
      if (Number(data.cpmCampaign) !== Number(defaultPricing.cpmCampaign)) {
        payload.cpmCampaign = data.cpmCampaign;
      }
      if (Number(data.cpmCrm) !== Number(defaultPricing.cpmCrm)) {
        payload.cpmCrm = data.cpmCrm;
      }
      if (Number(data.cpmEnrichment) !== Number(defaultPricing.cpmEnrichment)) {
        payload.cpmEnrichment = data.cpmEnrichment;
      }
      if (Number(data.cpmExport) !== Number(defaultPricing.cpmExport)) {
        payload.cpmExport = data.cpmExport;
      }

      if (
        !data.criteriaCategories.every(el =>
          defaultPricing?.criteriaCategoriesArray?.includes(el)
        )
      ) {
        payload.criteriaCategories = data.criteriaCategories;
      }
    } else {
      payload = data;
    }

    await PRICING.updatePricing(payload._id, payload);
    if (selectedGroup._id) {
      getGroupPricings(selectedGroup._id);
    }
    closeModal();
    setLoaded(false);
  };

  const onDelete = async () => {
    await PRICING.deletePricing(selectedPricing._id);
    closeModal();
    setLoaded(false);
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  if (!loaded) {
    return (
      <Container>
        <Loading loading />
      </Container>
    );
  }

  return (
    <Container>
      <Row spacing={1}>
        <Item>
          <FormSection title="Tarification par défaut" xs={3}>
            <Row>
              <Item xs={3}>
                <SearchField
                  onChange={filterRows}
                  datas={allRows}
                  titleHead="Recherche une tarification"
                  placeholder="Nom, Key..."
                />
              </Item>
              <Item justify="flex-end" xs>
                <ButtonCreate
                  onClick={addPricing}
                  text="Ajouter une tarification"
                />
                {openModal === 'modalCreate' && (
                  <ModalForm
                    open={openModal === 'modalCreate'}
                    onClose={closeModal}
                    onValidate={handleCreatePricing}
                    actionName="Créer"
                    data={{
                      ...selectedPricing,
                      categoriesList: categories
                    }}
                  />
                )}
              </Item>
            </Row>
            <Row>
              <Item>
                <TableData
                  rows={defaultRows}
                  headers={[
                    { label: 'Nom', id: 'name', sortKey: 'name' },
                    { label: 'Key', id: 'key', sortKey: 'key' },
                    {
                      label: 'Catégories de critères',
                      id: 'criteriaCategories'
                    },
                    {
                      label: 'CPM enrichissement',
                      id: 'cpmEnrichment',
                      sortKey: 'cpmEnrichment'
                    },
                    {
                      label: 'CPM campagne',
                      id: 'cpmCampaignBoth',
                      help: 'CPM campagne CRM / CPM campagne médias'
                    },
                    {
                      label: 'CPM export',
                      id: 'cpmExport',
                      sortKey: 'cpmExport'
                    }
                  ]}
                  onUpdate={isSuperAdmin ? e => handleUpdate(e, true) : null}
                  onDelete={
                    isSuperAdmin
                      ? e => {
                          selectPricing(e);
                          if (!isSuperAdmin) {
                            setModal('unauthorized');
                            return;
                          }
                          handleDelete(e);
                        }
                      : null
                  }
                />
              </Item>
            </Row>
          </FormSection>
        </Item>
      </Row>
      <Row spacing={1}>
        <Item>
          <FormSection title="Personnalisation organisation" xs={3}>
            <Container justify="flex-start" style={{ marginLeft: '25px' }}>
              <Row
                style={{ maxWidth: '350px', marginTop: '6px' }}
                justify="flex-start"
              >
                <Item xs={10} justify="flex-start">
                  <AutocompleteTextField
                    title="Organisation"
                    placeholder="Choisissez une organisation"
                    options={groups.map(el => ({
                      ...el,
                      label: el.name,
                      value: el
                    }))}
                    formatLabel={option => {
                      return `${option.name} (${option._id})`;
                    }}
                    small
                    value={selectedGroup}
                    onChange={e => handleSelectedGroup(e)}
                  />
                </Item>
              </Row>
              <Item justify="flex-end" xs style={{ marginTop: '40px' }}>
                <ButtonCreate
                  onClick={() => {
                    setModal('modalUpdateGroup');
                  }}
                  text="Réinitialiser"
                  reload
                />
              </Item>
            </Container>
            {selectedGroupPricings.length > 0 && (
              <Row>
                <Item>
                  <TableData
                    rows={selectedGroupPricings.map(row => ({
                      ...row,
                      name: (
                        <Text
                          style={{
                            fontWeight: row.name.fromGroup ? 'bold' : 'normal'
                          }}
                        >
                          {row.name.value}
                        </Text>
                      ),
                      cpmCampaignBoth: (
                        <span>
                          <span
                            style={{
                              fontWeight: row.cpmCampaignBoth.cpmCampaign
                                .fromGroup
                                ? 'bold'
                                : 'normal'
                            }}
                          >
                            {row.cpmCampaignBoth.cpmCampaign.value}
                          </span>
                          {' / '}
                          <span
                            style={{
                              fontWeight: row.cpmCampaignBoth.cpmCrm.fromGroup
                                ? 'bold'
                                : 'normal'
                            }}
                          >
                            {row.cpmCampaignBoth.cpmCrm.value}
                          </span>
                        </span>
                      ),
                      cpmEnrichment: (
                        <Text
                          style={{
                            fontWeight: row.cpmEnrichment.fromGroup
                              ? 'bold'
                              : 'normal'
                          }}
                        >
                          {row.cpmEnrichment.value}
                        </Text>
                      ),
                      cpmExport: (
                        <Text
                          style={{
                            fontWeight: row.cpmExport.fromGroup
                              ? 'bold'
                              : 'normal'
                          }}
                        >
                          {row.cpmExport.value}
                        </Text>
                      ),
                      criteriaCategories: (
                        <Text
                          style={{
                            fontWeight: row.criteriaCategories.fromGroup
                              ? 'bold'
                              : 'normal'
                          }}
                        >
                          {row.criteriaCategories.value}
                        </Text>
                      )
                    }))}
                    headers={[
                      { label: 'Nom', id: 'name', sortKey: 'name' },
                      { label: 'Key', id: 'key', sortKey: 'key' },
                      {
                        label: 'Catégories de critères',
                        id: 'criteriaCategories'
                      },
                      {
                        label: 'CPM enrichissement',
                        id: 'cpmEnrichment',
                        sortKey: 'cpmEnrichment'
                      },
                      {
                        label: 'CPM campagne',
                        id: 'cpmCampaignBoth',
                        help: 'CPM campagne CRM / CPM campagne médias'
                      },
                      {
                        label: 'CPM export',
                        id: 'cpmExport',
                        sortKey: 'cpmExport'
                      }
                    ]}
                    onUpdate={
                      adminRights?.general.customers.advanced
                        ? e => handleUpdate(e, false)
                        : null
                    }
                  />
                </Item>
              </Row>
            )}
          </FormSection>
        </Item>
      </Row>
      {openModal === 'modalCreate' && (
        <ModalForm
          open={openModal === 'modalCreate'}
          onClose={closeModal}
          onValidate={handleCreatePricing}
          data={{ categoriesList: categories }}
          actionName="Créer"
        />
      )}
      {openModal === 'modalUpdate' && (
        <ModalForm
          open={openModal === 'modalUpdate'}
          onClose={closeModal}
          onValidate={handleUpdatePricing}
          data={{
            categoriesList: categories,
            ...selectedPricing
          }}
          actionName="Créer"
        />
      )}
      {openModal === 'modalDelete' && (
        <ModalDelete
          name={selectedPricing.name}
          type="cette tarification"
          open={openModal === 'modalDelete'}
          onClose={closeModal}
          onDelete={() => {
            onDelete();
          }}
        />
      )}
      {openModal === 'modalUpdateGroup' && (
        <ModalToDefault
          open={openModal === 'modalUpdateGroup'}
          onClose={closeModal}
          onConfirm={updateGroupPricing}
        />
      )}
      {openModal === 'unauthorized' && (
        <UnauthorizedModal
          open={openModal === 'unauthorized'}
          onClose={closeModal}
        />
      )}
    </Container>
  );
};

export default PricingContainer;
